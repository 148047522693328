<div class="container d-flex bg-primary align-items-center">
  <div class="card bg-black shadow border-bottom-0 mx-auto">
    <div class="card-body text-center py-4">
      <div class="header d-flex flex-row justify-content-center align-items-center">
        <span class="icon material-icons text-white mr-2">add_card</span>
        <h6 class="font-weight-bold text-white m-0">{{ 'card.failure.page.title' | translate }}</h6>
      </div>
      <div class="my-4 sub-heading-modal">
        <div class="mt-3 text-white">{{ 'card.failure.page.info' | translate }}</div>
      </div>
      <div class="btn-container mt-3 d-block">
        <button id="back-button" [routerLink]="['/payment']" type="button" class="btn mx-2 mt-4 font-weight-bold">
          {{ 'card.failure.page.button.text' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

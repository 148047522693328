import { Component, Input, OnInit } from '@angular/core';
import { CardToken, CardTokenQuery, CardService } from '@fgb/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'fgb-kings-pay-button',
  templateUrl: './kings-pay-button.component.html',
  styleUrls: ['./kings-pay-button.component.scss']
})
export class KingsPayButtonComponent implements OnInit {
  @Input() link: string = '/payment';
  defaultCardToken$: Observable<CardToken>;

  constructor(
    private cardTokenQuery: CardTokenQuery,
    private cardTokenService: CardService) { }

  ngOnInit(): void {
    this.defaultCardToken$ = this.cardTokenService.fetchCards()
      .pipe(switchMap(() => this.cardTokenQuery.selectDefaultCard()));
  }

}

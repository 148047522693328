<div class="how-it-works" [ngClass]="{ 'first-time-style': !isRedirectFormHomePage }">
  <img src="assets/images/logo-white.svg" alt="" />
  <div class="mt-4 mb-5">
    <div class="title">{{ 'how.it.work.title' | translate }}</div>
    <div class="sub-title">{{ 'how.it.work.sub.title' | translate }}</div>
  </div>
  <div class="content">
    <div class="attend-games">
      <div class="material-icons">local_police</div>
      <div class="text">{{ 'how.it.work.attend.game' | translate }}</div>
    </div>
    <div class="rewards">
      <div class="material-icons">redeem</div>
      <div class="text">{{ 'how.it.work.rewards' | translate }}</div>
    </div>
    <div class="redeem">
      <div class="material-icons">qr_code_scanner</div>
      <div class="text">{{ 'how.it.work.redeem' | translate }}</div>
    </div>
  </div>
  <button class="btn btn-back" (click)="submitStep()">
    <a>{{ routerLink | translate }}</a>
  </button>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberPurchaseItemDetailsComponent } from './components/member-purchase-item-details/member-purchase-item-details.component';
import { MemberPurchaseListComponent } from './components/member-purchase-list/member-purchase-list.component';
import { MemberPurchaseListItemComponent } from './components/member-purchase-list-item/member-purchase-list-item.component';
import { MixedLoyaltyWalletItemComponent } from './components/mixed-loyalty-wallet-item/mixed-loyalty-wallet-item.component';
import { MixedLoyaltyWalletListComponent } from './components/mixed-loyalty-wallet-list/mixed-loyalty-wallet-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuctionBidListComponent } from './components/auction-bid-list/auction-bid-list.component';
import { AuctionBidListItemComponent } from './components/auction-bid-list-item/auction-bid-list-item.component';
import { AuctionBidDetailsComponent } from './components/auction-bid-details/auction-bid-details.component';
import { LayoutModule } from '../layouts/layout.module';
import { UserModule } from '../user/user.module';
import { InlineSVGModule } from 'ng-inline-svg';

@NgModule({
  declarations: [
    MemberPurchaseItemDetailsComponent,
    MemberPurchaseListComponent,
    MemberPurchaseListItemComponent,
    AuctionBidListComponent,
    AuctionBidListItemComponent,
    AuctionBidDetailsComponent,
    MixedLoyaltyWalletItemComponent,
    MixedLoyaltyWalletListComponent
  ],
  exports: [
    AuctionBidListComponent,
    AuctionBidListItemComponent,
    AuctionBidDetailsComponent,
    MemberPurchaseItemDetailsComponent,
    MemberPurchaseListComponent,
    MemberPurchaseListItemComponent,
    MixedLoyaltyWalletItemComponent,
    MixedLoyaltyWalletListComponent
  ],
  imports: [
    LayoutModule,
    UserModule,
    CommonModule,
    SharedModule,
    FormsModule,
    QRCodeModule,
    RouterModule,
    NgbModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
  ],
})
export class PurchaseModule {}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  private _destroyed$ = new Subject();
  showNewFanWidget: boolean = false;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.checkNewFanWidgetFlag();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * Check flag of
   * show new fan widget
   */
  checkNewFanWidgetFlag() {
    this.translate
      .stream('newfan.show.flag')
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data: string) => {
        if (data === 'true') {
          this.showNewFanWidget = JSON.parse(data);
        }
      });
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() title: string = 'banner.default.page.title';

  constructor() { }

  ngOnInit(): void {
  }

}

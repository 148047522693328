

<ng-container *ngSubscribe="defaultCardToken$ | withLoading as result">
    <a class="card-button" role="link" [routerLink]="link" [class.frozen]="result.data?.Frozen">
        <h3 class="card-title">{{ 'kings.pay.button.title' | translate }}</h3>
            <ng-container *ngIf="!result.isLoading; else loading">
                <ng-container [ngTemplateOutlet]="result.data ? withCard : noCard" 
                              [ngTemplateOutletContext]="{ $implicit: result.data }">
                </ng-container>
            </ng-container>
    </a>
</ng-container>

<!-- LOADING -->
<ng-template #loading>
    <fgbcl-loading size="small"></fgbcl-loading>
</ng-template>

<!-- NO CARD TEMPLATE -->
<ng-template #noCard>
    <div class="card-number-container no-card">
        <span class="material-icons">add_card</span>
        <span class="add-card-text">Add Card</span>
    </div>
</ng-template>

<!-- CARD TEMPLATE -->
<ng-template #withCard let-card>
    <div class="card-number-container default-card">
        <span class="card-number font-size-14">**** {{ card?.CardNumber.substr(12) }}</span>
        <img alt="" class="card-type-image" src="{{ card?.CardType | cardTypeImageUrl }}" />
    </div>
</ng-template>
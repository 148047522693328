import { Component, OnInit } from '@angular/core';
import {
  ProviderName,
  TransactionType,
  MemberCard,
  MemberCardQuery,
  BarcodeService,
  BarcodeQuery
} from '@fgb/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Regex } from 'src/app/shared/utilities/regex';

@Component({
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent implements OnInit {

  transactionStartDate: Date;
  transactionEndDate: Date;
  transactionTypes = TransactionType;
  card$: Observable<MemberCard | undefined>;
  barcode$: Observable<any>;

  regex = Regex;
  provider = ProviderName.FreedomPay;

  constructor(private memberCardQuery: MemberCardQuery,
              private barcodeService: BarcodeService,
              private barcodeQuery: BarcodeQuery) {}

  ngOnInit() {
    this.transactionEndDate = new Date();
    this.transactionStartDate = new Date();
    this.barcodeService.fetchBarcode().toPromise();
    this.transactionStartDate.setDate(new Date(this.transactionStartDate).getDate() - 1);
    this.card$ = this.memberCardQuery.selectCurrentCard();
    this.barcode$ = this.barcodeQuery.select()
      .pipe(filter((code: any) => !!code.Barcode));
    
  }
}

<div class="card border-0 shadow">
  <div class="card-header din-bold border-0 px-2 py-0 mt-3 mb-1 font-size-16 text-primary">
    {{ 'voucher.label' | translate }}
  </div>
  <div class="font-size-14">{{ 'voucher.instructions' | translate }}</div>
  <div class="card-body py-0">
    <!--result text-->
    <div class="result-box">
      <div class="result font-size-8">
        <fgb-errors></fgb-errors>
        <div class="{{ redeemResultTheme }}" *ngIf="redemptionResult">
          {{ redemptionResult }}
        </div>
      </div>
    </div>
    <!--submit btn-->
    <div class="input-group mb-2 input-code-and-btn mx-auto">
      <input type="text" [(ngModel)]="voucherCode" [placeholder]="inputPlaceholder"
        class="form-control text-center border-0 input-group input-field" (keyup.enter)="redeemVoucher()" />
      <div class="input-group-append">
        <button class="btn text-white font-weight-bold py-0 submit-btn" type="button" (click)="redeemVoucher()"
          [disabled]="disabled"> <span class="material-icons">
            arrow_forward_ios
          </span></button>
      </div>

    </div>
  </div>
</div>
<!-- Banner -->
<fgb-banner></fgb-banner>
<!-- Card -->
<ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.payment'">
  <div class="container mb-4">
    <fgb-kings-pay-button></fgb-kings-pay-button>
  </div>
</ng-container>
<!-- Navbar -->
<div class="container">
  <fgb-account-nav></fgb-account-nav>
</div>
<!-- Badge overview list -->
<h1 class="accessibility-only">Badges</h1>
<div class="container mt-4">
  <fgbcl-badge-overview-list [designV2]="true" [phaseThree]="true"></fgbcl-badge-overview-list>
</div>

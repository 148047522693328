<!-- BANNER -->
<fgb-banner></fgb-banner>
<div class="container">
  <!--Back btn-->
  <div class="text-white din-bold my-4 cursor-pointer" routerLink="/">
    <span class="icon icon-arrow-1-left font-weight-bold pr-1 font-size-12"></span
    ><span class="font-size-14">{{ 'back.terms.page' | translate }}</span>
  </div>
  <!--Terms-->
  <div class="row">
    <div class="col-12">
      <fgbcl-terms-and-conditions [type]="termsAndConditionsType" [defaultTerms]="defaultTemplate"> </fgbcl-terms-and-conditions>
    </div>
  </div>
</div>

<ng-template #defaultTemplate>
  <p class="terms-section-content text-left mb-3">Default terms coming soon.</p>
</ng-template>

<ng-container *ngIf="transactions$ | async as transactions">
  <ng-container *ngIf="transactions$ | async as transactions">
    <div *ngFor="let day of transactions | keyvalue : keepOrder">
      <h5 class="transaction-date mt-4 mb-3 text-white font-size-16">{{ day.key }}</h5>
      <div class="mb-0 mb-lg-5">
        <div class="card my-3 py-2 px-4 border-0" *ngFor="let transaction of day.value">
          <div class="row">
            <div class="col-8 text-left">
              <div class="font-weight-bold text-white transaction-text">
                {{ transaction.TransactionDescription }}
              </div>

              <div class="transaction-subtext">
                {{ transaction.TransactionDateUTC | UtcToLocaltimePipe : 'hh:mm a' | uppercase }}
              </div>
            </div>
            <div class="col-4 text-right">
              <div class="font-weight-bold transaction-text text-capitalize text-white points">
                {{ transaction.Amount >= 0 ? '+' : ''
                }}{{ transaction.Amount | FortressCurrencyPipe : (transactionType === transactionTypes.loyalty ? 'PTS' : 'USD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.loyalty">
      <div>
        <img class="no-activity-image d-flex mx-auto" src="assets/images/icons/no-activity.svg" />
        <h5 class="text-primary text-center mt-3 mb-0 font-weight-bold text-capitalize mt-4 font-size-16">No Activity</h5>
        <p class="text-white text-center font-size-12">Available for the dates selected</p>
      </div>
    </ng-container>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.ecash">
      <div class="d-flex flex-column justify-content-center align-items-center my-4">
        <span class="icon material-icons text-primary no-transaction-icon">history</span>
        <h5 class="text-white text-center mb-0 font-weight-bold text-capitalize mt-2 font-size-16">
          {{ 'transaction.list.no.transaction.title' | translate }}
        </h5>
        <p class="text-white text-center font-size-12">{{ 'transaction.list.no.transaction.subtitle' | translate }}</p>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<div class="full-page">
  <div class="registration-panel bg-white">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 user-login-panel">
          <h2 class="text-center mt-3">Review Purchases</h2>

          <!-- PAYMENT SUMMARY -->
          <ng-container *ngIf="!paymentInitialised">
            <div *ngIf="basket$ | async as basket; else loading" class="p-4">
              <ng-container *ngIf="basket">
                <h4>Payment Summary</h4>

                <div class="row">
                  <!-- Left Side -->
                  <div class="col-lg-7">
                    <hr class="border-info my-2 w-100" />
                    <div class="font-size-12">
                      <div class="row font-weight-bold">
                        <div class="col-9">Membership Option</div>
                        <div class="col-3">Cost</div>
                      </div>

                      <div *ngFor="let basketItem of basket.BasketItems" class="row text-info my-2">
                        <div class="col-5 col-md-6">{{ basketItem.MembershipOption.DisplayName }}</div>
                        <div class="col-3 col-md-2">{{ basketItem.Price / 100 | FortressCurrencyPipe }}</div>
                      </div>
                    </div>
                    <hr class="border-info my-2 w-100" />
                  </div>

                  <!-- Right Side -->
                  <div [ngClass]="{ 'd-none': editingBillingAddress || editingShippingAddress }" class="col-lg-5">
                    <div class="position-relative rounded bg-muted p-2 font-size-14">
                      <div class="top-right text-primary font-weight-bold cursor-pointer">
                        <a (click)="editBillingAddress()">Change</a>
                      </div>
                      <h6 class="mb-1">Billing Address</h6>
                      <div class="text-info">
                        <p class="mb-0">{{ billingAddress.Street }}</p>
                        <p class="mb-0">{{ billingAddress.Town }}</p>
                        <p class="mb-0">{{ billingAddress.County }}</p>
                        <p class="mb-0">{{ billingAddress.Postcode }}</p>
                        <p class="mb-0">{{ billingAddress.Country }}</p>
                      </div>
                    </div>
                    <div class="position-relative rounded bg-muted p-2 my-2 font-size-14">
                      <div class="top-right text-primary font-weight-bold cursor-pointer">
                        <a (click)="editShippingAddress()">Change</a>
                      </div>
                      <h6 class="mb-1">Shipping Address</h6>
                      <div class="text-info">
                        <p class="mb-0">{{ shippingAddress.Street }}</p>
                        <p class="mb-0">{{ shippingAddress.Town }}</p>
                        <p class="mb-0">{{ shippingAddress.County }}</p>
                        <p class="mb-0">{{ shippingAddress.Postcode }}</p>
                        <p class="mb-0">{{ shippingAddress.Country }}</p>
                      </div>
                    </div>

                    <fgb-errors></fgb-errors>

                    <!-- Giftcode input -->
                    <div class="input-group rounded my-2">
                      <input
                        type="text"
                        class="form-control border-0 bg-muted gift-code-input"
                        [(ngModel)]="giftCodeInput"
                        (blur)="detectGiftCodeInput(giftCodeInput)"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-success text-white form-control heading-text-one h-100"
                          type="button"
                          (click)="applyGiftCode()"
                        >
                          Apply Gift Code
                        </button>
                      </div>
                    </div>

                    <div class="rounded bg-muted p-2 my-2">
                      <h6 class="mb-3">Summary</h6>
                      <div class="payment-breakdown-container">
                        <div class="row text-info font-size-12">
                          <div class="col-9">Items:</div>
                          <div class="col-3 text-right">{{ basket.TotalPrice / 100 | FortressCurrencyPipe }}</div>
                        </div>
                        <div class="row text-info font-size-12">
                          <div class="col-9">Shipping:</div>
                          <div class="col-3 text-right">{{ basket.TotalShipping / 100 | FortressCurrencyPipe }}</div>
                        </div>
                        <div class="row text-info font-size-12">
                          <div class="col-9">Tax:</div>
                          <div class="col-3 text-right">{{ basket.TotalTax / 100 | FortressCurrencyPipe }}</div>
                        </div>
                        <div class="row font-size-12" *ngIf="appliedGiftCodes && appliedGiftCodes.length > 0">
                          <div class="col-12">Discount Code(s):</div>
                          <ng-container *ngFor="let giftCode of appliedGiftCodes">
                            <div class="col-9 heading-text">
                              <button (click)="removeGiftCode(giftCode.Code)">X</button> {{ giftCode.Code }}
                            </div>
                            <div class="col-3 text-right">
                              <span *ngIf="giftCode.EntitlementValueType == 0"
                                >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
                              >
                              <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
                              <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="row font-weight-bold font-size-14 mt-2">
                        <div class="col-9">Total (Incl. Shipping):</div>
                        <div class="col-3 text-info text-right">{{ totalBasketPrice / 100 | FortressCurrencyPipe }}</div>
                      </div>
                    </div>
                  </div>

                  <!-- EDIT BILLING ADDRESS FORM -->
                  <ng-container *ngIf="editingBillingAddress">
                    <div class="col-lg-5">
                      <div class="position-relative rounded bg-muted p-2">
                        <div class="top-right text-primary font-weight-bold cursor-pointer font-size-14">
                          <a (click)="clearAddressForm(billingAddressForm)">Clear Form</a>
                        </div>
                        <h6 class="mb-2 font-size-14">Billing Address</h6>
                        <form [formGroup]="billingAddressForm">
                          <div class="form-row">
                            <div class="form-group col-6">
                              <label for="FirstName">
                                First Name
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                id="FirstName"
                                class="form-control"
                                type="text"
                                formControlName="FirstName"
                                [ngClass]="{
                                  'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.FirstName.errors
                                }"
                                required
                              />
                              <div class="invalid-feedback">Please enter your first name.</div>
                            </div>

                            <div class="form-group col-6">
                              <label for="Surname">
                                Surname
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                id="Surname"
                                class="form-control"
                                type="text"
                                formControlName="Surname"
                                [ngClass]="{
                                  'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Surname.errors
                                }"
                                required
                              />
                              <div class="invalid-feedback">Please enter your surname.</div>
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="Street">
                              Street
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              id="Street"
                              class="form-control"
                              name="Street"
                              type="text"
                              formControlName="Street"
                              [ngClass]="{
                                'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Street.errors
                              }"
                              required
                            />
                            <div class="invalid-feedback">Please enter a value.</div>
                          </div>

                          <div class="form-group">
                            <label for="Town">
                              City
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              id="Town"
                              class="form-control"
                              name="Town"
                              type="text"
                              formControlName="Town"
                              [ngClass]="{
                                'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Town.errors
                              }"
                              required
                            />
                            <div class="invalid-feedback">Please enter a value.</div>
                          </div>

                          <div class="form-group">
                            <label for="County">
                              State
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              id="County"
                              class="form-control"
                              name="County"
                              type="text"
                              formControlName="County"
                              [ngClass]="{
                                'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.County.errors
                              }"
                              required
                            />
                            <div class="invalid-feedback">Please enter a value.</div>
                          </div>

                          <div class="form-group">
                            <label for="Postcode">
                              Zip Code
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              id="Postcode"
                              class="form-control"
                              name="Postcode"
                              type="text"
                              formControlName="Postcode"
                              [ngClass]="{
                                'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Postcode.errors
                              }"
                              required
                            />
                            <div class="invalid-feedback">Please enter a valid zip code.</div>
                          </div>

                          <div class="form-group">
                            <label for="Country">
                              Country
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              id="Country"
                              class="form-control"
                              name="Country"
                              type="text"
                              formControlName="Country"
                              [ngClass]="{
                                'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Country.errors
                              }"
                              required
                            />
                            <div class="invalid-feedback">Please enter a value.</div>
                          </div>
                        </form>
                        <div class="d-flex justify-content-between my-2">
                          <button class="btn btn-danger" (click)="editingBillingAddress = false">Cancel</button>
                          <button class="btn btn-primary" (click)="updateBillingAddress()">Save</button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="editingShippingAddress">
                    <div class="col-lg-5">
                      <div class="position-relative rounded bg-muted p-2">
                        <div class="top-right text-primary font-weight-bold cursor-pointer font-size-14">
                          <a (click)="clearAddressForm(shippingAddressForm)">Clear Form</a>
                        </div>
                        <h6 class="mb-2 font-size-14">Shipping Address</h6>
                        <form [formGroup]="shippingAddressForm">
                          <div class="form-row">
                            <div class="form-group col-6">
                              <label for="FirstName">
                                First Name
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                id="FirstName"
                                class="form-control"
                                type="text"
                                formControlName="FirstName"
                                [ngClass]="{
                                  'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.FirstName.errors
                                }"
                                required
                              />
                              <div class="invalid-feedback">Please enter your first name.</div>
                            </div>

                            <div class="form-group col-6">
                              <label for="Surname">
                                Surname
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                id="Surname"
                                class="form-control"
                                type="text"
                                formControlName="Surname"
                                [ngClass]="{
                                  'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Surname.errors
                                }"
                                required
                              />
                              <div class="invalid-feedback">Please enter your surname.</div>
                            </div>
                          </div>

                          <div class="form-group">
                            <label for="Street">
                              Street
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              id="Street"
                              class="form-control"
                              name="Street"
                              type="text"
                              formControlName="Street"
                              [ngClass]="{
                                'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Street.errors
                              }"
                              required
                            />
                            <div class="invalid-feedback">Please enter a value.</div>
                          </div>

                          <div class="form-group">
                            <label for="Town">
                              City
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              id="Town"
                              class="form-control"
                              name="Town"
                              type="text"
                              formControlName="Town"
                              [ngClass]="{
                                'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Town.errors
                              }"
                              required
                            />
                            <div class="invalid-feedback">Please enter a value.</div>
                          </div>

                          <div class="form-group">
                            <label for="County">
                              State
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              id="County"
                              class="form-control"
                              name="County"
                              type="text"
                              formControlName="County"
                              [ngClass]="{
                                'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.County.errors
                              }"
                              required
                            />
                            <div class="invalid-feedback">Please enter a value.</div>
                          </div>

                          <div class="form-group">
                            <label for="Postcode">
                              Zip Code
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              id="Postcode"
                              class="form-control"
                              name="Postcode"
                              type="text"
                              formControlName="Postcode"
                              [ngClass]="{
                                'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Postcode.errors
                              }"
                              required
                            />
                            <div class="invalid-feedback">Please enter a valid zip code.</div>
                          </div>

                          <div class="form-group">
                            <label for="Country">
                              Country
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              id="Country"
                              class="form-control"
                              name="Country"
                              type="text"
                              formControlName="Country"
                              [ngClass]="{
                                'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Country.errors
                              }"
                              required
                            />
                            <div class="invalid-feedback">Please enter a value.</div>
                          </div>
                        </form>
                        <div class="d-flex justify-content-between my-2">
                          <button class="btn btn-danger" (click)="editingShippingAddress = false">Cancel</button>
                          <button class="btn btn-primary" (click)="updateShippingAddress()">Save</button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>

          <!-- EMBEDDED PAYMENT WINDOW -->
          <ng-container *ngIf="paymentInitialised">
            <!-- Summary -->
            <div *ngIf="basket$ | async as basket; else loading" class="rounded bg-muted p-2 my-2">
              <h6 class="mb-4">Summary</h6>
              <div class="row text-info font-size-12">
                <div class="col-9">Items:</div>
                <div class="col-3">{{ basket.TotalPrice / 100 | FortressCurrencyPipe }}</div>
              </div>
              <div class="row text-info font-size-12">
                <div class="col-9">Shipping:</div>
                <div class="col-3">{{ basket.TotalShipping / 100 | FortressCurrencyPipe }}</div>
              </div>
              <div class="row text-info font-size-12">
                <div class="col-9">Tax:</div>
                <div class="col-3">{{ basket.TotalTax / 100 | FortressCurrencyPipe }}</div>
              </div>
              <fgb-errors></fgb-errors>
              <div class="row font-size-12">
                <div class="col-12">Discount Code(s):</div>
                <ng-container *ngFor="let giftCode of appliedGiftCodes">
                  <div class="col-9">{{ giftCode.Code }}</div>
                  <div class="col-3">
                    <span *ngIf="giftCode.EntitlementValueType == 0"
                      >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
                    >
                    <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
                    <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
                  </div>
                </ng-container>
              </div>
              <div class="row font-weight-bold font-size-14 mt-2">
                <div class="col-9">Total (Incl. Shipping):</div>
                <div class="col-3 text-info">{{ totalBasketPrice / 100 | FortressCurrencyPipe }}</div>
              </div>
            </div>
            <!-- Payment Provider Form -->
            <div id="paymentdiv">
              <div class="text-center p-4">
                <fgb-loading></fgb-loading>
              </div>
            </div>
          </ng-container>

          <!-- LOADING SPINNER  -->
          <ng-template #loading>
            <div class="text-center p-4">
              <fgb-loading></fgb-loading>
            </div>
          </ng-template>

          <fgb-registration-navigation
            *ngIf="!editingBillingAddress && !paymentInitialised"
            (nextButtonClicked)="submitStep()"
            [stepType]="stepType"
          ></fgb-registration-navigation>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #unappliedGiftCodeModal let-modal>
  <div class="text-center transfer-status-modal">
    <p class="font-weight-bold text-center text-danger mt-4 mb-0">The following gift code has not been applied:</p>
    <p class="text-info unapplied-code-instruction">Please remove or apply this code</p>
    <p class="font-weight-bold text-center text-success my-4">
      {{ giftCodeInput }}
    </p>
    <div class="d-flex justify-content-between mx-lg-4 mx-2">
      <button class="btn btn-danger font-weight-bold my-4" (click)="modal.dismiss('close'); removeUnappliedGiftCode()">
        Remove Code
      </button>
      <button class="btn btn-success font-weight-bold my-4" (click)="modal.dismiss('close'); applyGiftCode()">Apply Code</button>
    </div>
  </div>
</ng-template>

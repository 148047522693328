<div class="full-page container-fluid w-100 d-flex">
  <div class="m-auto text-center">
    <img src="assets/images/logo-secondary.svg" class="logo mb-5" />
    <h4
      class="text-secondary mb-3"
      [translate]="'maintenancepage.title'"
      [translateParams]="{ fgbdefault: 'MAINTENANCE PAGE' }"
    ></h4>
    <p
      class="text-black font-size-14 px-4"
      [translate]="'maintenancepage.description'"
      [translateParams]="{ fgbdefault: 'Sorry, we’re down for scheduled maintenance right now. Please check back later' }"
    ></p>
  </div>
</div>

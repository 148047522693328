<div class="full-page bg-black container-fluid w-100 p-0">
  <div class="row m-0 wrap-page">
    <div class="col-lg-6 d-none d-lg-block login-banner"></div>
    <div class="col-lg-6 col-12 text-left bg-white p-0">
      <div class="h-100">
        <div class="mobile-logo bg-secondary d-flex d-lg-none justify-content-center mb-3">
          <img src="assets/images/logo-white.svg" class="logo" />
        </div>
        <div class="col-12" [ngClass]="{'welcome-page-style': isWelcomePage}">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container d-flex align-items-center">
  <div class="card shadow bg-primary border-bottom-0 mx-auto">
    <div class="card-body text-white text-center py-4">
      <h3 class="card-heading">{{ 'add.card.fail.title' | translate }}</h3>
      <div class="my-4 sub-heading-modal">
        <div class="font-weight-bold">{{ 'add.card.fail.desc.one' | translate }}</div>
        <div class="mt-3">{{ 'add.card.fail.desc.two' | translate }}</div>
      </div>
      <div class="btn-container mt-3 d-block">
        <button [routerLink]="['/payment']" type="button" class="btn btn-secondary mx-2 mt-4 btn-text font-weight-bold px-5">
          {{ 'add.card.fail.btn.text' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  PopulationGuard,
  StepType,
  MemberResolver,
  MemberCardResolver,
  BalanceResolver,
  RegistrationStepResolver,
  AddressResolver,
  CountryResolver,
  CardTokenResolver,
  WebsiteContentFeatureFlagGuard,
  MaintenanceGuard,
} from '@fgb/core';

// General
import { MasterPageComponent } from './pages/master/master-page/master-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
// Unauthenticated
import { AuthCallbackComponent } from './modules/user/components/auth/auth-callback/auth-callback.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginAccountsPageComponent } from './pages/login-accounts-page/login-accounts-page.component';
import { SingleSignOnComponent } from './modules/user/components/single-sign-on/single-sign-on.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
// Registration
import { RegistrationLayoutPageComponent } from './modules/registration/pages/registration-layout-page/registration-layout-page.component';
import { RegistrationPaymentComponent } from './modules/registration/pages/steps/registration-payment/registration-payment.component';

// Partners Pages
import { TmAuthCallbackComponent } from './shared/components/providers/tm/tm-auth-callback/tm-auth-callback.component';
import { V1SingleSignOnComponent } from './modules/user/components/v1-single-sign-on/v1-single-sign-on.component';
// Offer Pages
import { SportsAllianceCallbackComponent } from './shared/components/providers/sports-alliance/sports-alliance-callback/sports-alliance-callback.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { BadgesPageComponent } from './pages/badges-page/badges-page.component';
import { PaymentPageComponent } from './pages/payment-page/payment-page.component';
import { EcashSuccessPageComponent } from './pages/ecash-page/ecash-success-page/ecash-success-page.component';
import { EcashFailurePageComponent } from './pages/ecash-page/ecash-failure-page/ecash-failure-page.component';
import { EcashResultPageComponent } from './pages/ecash-page/ecash-result-page/ecash-result-page.component';
import { CardFailurePageComponent } from './pages/card-page/card-failure-page/card-failure-page.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: MasterPageComponent,
    resolve: {
      member: MemberResolver,
      memberCard: MemberCardResolver,
      balances: BalanceResolver,
      cardTokens: CardTokenResolver,
    },
    children: [
      {
        path: '',
        canActivate: [PopulationGuard],
        data: {
          exclude: ['testPop'],
          popRedirect: 'test',
        },
        component: HomePageComponent,
      },
      {
        path: 'payment',
        resolve: { balances: BalanceResolver },
        component: PaymentPageComponent,
        canActivate: [WebsiteContentFeatureFlagGuard],
        data: {
          featureKey: 'feature.flag.payment',
          redirectTo: '',
        },
      },
      { path: 'faq', component: FaqPageComponent },
      { path: 'badges', component: BadgesPageComponent },
      { path: 'terms', component: TermsPageComponent },
      { path: '404page', component: NotFoundPageComponent },
    ],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent },
  { path: 'login/accounts', component: LoginAccountsPageComponent },
  {
    path: 'register',
    resolve: { registrationStep: RegistrationStepResolver, country: CountryResolver },
    children: [
      {
        path: 'payment',
        children: [
          {
            path: '',
            component: RegistrationPaymentComponent,
            data: { registrationStep: StepType.Payment },
            resolve: { member: MemberResolver, address: AddressResolver },
          },
        ],
      },
    ],
  },
  {
    path: 'cardtoken',
    children: [
      { path: 'success', component: EcashSuccessPageComponent },
      { path: 'failure', component: EcashFailurePageComponent },
      { path: 'result', component: EcashResultPageComponent },
    ],
  },
  {
    path: 'card',
    children: [{ path: 'failure', component: CardFailurePageComponent }],
  },
  {
    path: 'register',
    component: RegistrationLayoutPageComponent,
    loadChildren: () => import('@fgb/portal-component-library/src/lib/registration').then((m) => m.RegistrationWithRouteModule),
    canActivateChild: [MaintenanceGuard],
    resolve: { CountryResolver },
  },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'callback', component: AuthCallbackComponent },
  { path: 'sso', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId', component: SingleSignOnComponent },
  { path: 'login/ss/login.aspx', component: V1SingleSignOnComponent },
  { path: 'maintenance', component: MaintenancePageComponent },
  { path: 'tm-auth-callback', component: TmAuthCallbackComponent },
  { path: 'sa-callback', component: SportsAllianceCallbackComponent },
  { path: '**', redirectTo: '404page' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

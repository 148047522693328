<!-- Banner -->
<fgb-banner></fgb-banner>
<!-- Card -->
<ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.payment'">
  <div class="container mb-4">
    <fgb-kings-pay-button></fgb-kings-pay-button>
  </div>
</ng-container>
<!-- Navbar account -->
<div class="container">
  <fgb-account-nav></fgb-account-nav>

  <button class="btn how-it-work mb-4">
    <a [routerLink]="'/how-it-works'" [queryParams]="{ isHomePage: true }"> {{ 'how.it.work' | translate }} </a>
  </button>
</div>
<!--voucher component-->

<div class="container text-center my-3">
  <!--Voucher widget-->
  <div class="mb-3">
    <fgb-vouchers></fgb-vouchers>
  </div>

  <!--New fan widget-->
  <ng-container *ngIf="showNewFanWidget">
    <div class="d-flex new-fan-widget bg-primary my-3">
      <div class="m-auto text-white">
        <h4 class="new-fan-widget-title mb-3">{{ 'newfan.title' | translate }}</h4>
        <h3 class="new-fan-widget-description mb-0">{{ 'newfan.description' | translate }}</h3>
        <p class="font-size-12 mb-0">{{ 'newfan.terms' | translate }}</p>
      </div>
    </div>
  </ng-container>
</div>

<!--Scorecard list-->
<fgbcl-scorecard-list-view [icon]="'chevron_right'" [version2Design]="true" [phaseThree]="true"></fgbcl-scorecard-list-view>
<!-- <fgb-scorecard-list-view [icon]="'chevron_right'"></fgb-scorecard-list-view> -->

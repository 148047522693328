<!-- BANNER -->
<fgb-banner></fgb-banner>

<div class="container">
  <!--Back btn-->
  <div class="text-white din-bold my-4 cursor-pointer" routerLink="/">
    <span class="icon icon-arrow-1-left font-weight-bold pr-1 font-size-12"></span
    ><span class="font-size-14">{{ 'back.faqs.page' | translate }}</span>
  </div>
  <!--FAQs-->
  <div class="row faq-container">
    <div class="col-12">
      <fgbcl-faq-section></fgbcl-faq-section>
    </div>
  </div>
</div>

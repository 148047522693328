import { Component, OnInit } from '@angular/core';
import { VoucherService, VoucherRedeemModel, BalanceService, config, ErrorStateService } from '@fgb/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgb-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss'],
})
export class VouchersComponent implements OnInit {
  voucherCode: string;
  redemptionResult: string;
  private successMessage: string = 'Code successfully redeemed.';
  disabled: boolean;
  inputPlaceholder: string = '';
  private _destroyed$ = new Subject();
  redeemResultTheme: string = '';

  constructor(
    private voucherService: VoucherService,
    private balanceService: BalanceService,
    private translate: TranslateService,
    private errorService: ErrorStateService
  ) {}

  ngOnInit() {
    this.translate
      .stream('voucher.placeholder')
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data: string) => {
        this.inputPlaceholder = data;
      });
    this.translate
      .stream('voucher.success-voucher')
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data: string) => {
        this.successMessage = data;
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  redeemVoucher() {
    if (!this.voucherCode || !this.voucherCode.trim()) {
      this.redeemResultTheme = 'text-danger';
      this.errorService.clearErrors();
      this.translate
        .stream('voucher.empty-code')
        .pipe(takeUntil(this._destroyed$))
        .subscribe((data: string) => {
          this.redemptionResult = data;
          this.resetResult();
        });
      return;
    }
    this.disabled = true;

    setTimeout(() => {
      this.disabled = false;
    }, 500);

    let voucher = new VoucherRedeemModel();
    voucher.VoucherCode = this.voucherCode;
    this.redeemResultTheme = 'text-success';
    this.redemptionResult = '';
    this.voucherService
      .redeemVoucher(voucher)
      .toPromise()
      .then((item) => {
        this.balanceService.fetchBalance(config.purseConfig.virtualLoyalty).pipe(take(1)).subscribe();
        this.redemptionResult = this.successMessage;
        this.resetResult();
      }).catch(error=>{
       setTimeout(() => {
        this.errorService.clearErrors();
       }, 10000);
      });
    
  }
  resetResult(){
    setTimeout(() => {
      this.redemptionResult = '';
    }, 10000);
  }
}
